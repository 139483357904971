<template>
  <div class="collection-cart-container d-grid">
    <div v-for="(collection, i) in list" :key="'collection_'+i" class="d-block collection-cart text-decoration-none">
      <nuxt-link
        :to="{ path: localePath(`/gold/${collection.to}`) }"
        type="button"
        tag="a"
        class="flex-grow-1 flex-shrink-0 btn d-flex shadow-none rounded-0 p-0 position-relative align-items-center justify-content-center w-100"
        v-if="!switchRoute"
        style="padding: 10px"
      >
        <figure
          class="collection-cart__figure w-100 position-relative overflow-hidden overlay"
        >
          <img
            v-lazy-load
            :data-src="`/images/collections/WEB/Gold/${collection.imgDesk}`"
            class="collection-cart__figure__image w-100"
            :alt="collection.title"
          >
        </figure>
        <h3 class="collection-cart__title text-center text-white position-absolute z-index999">{{ $t(collection.title) }}</h3>
      </nuxt-link>
      <nuxt-link
        :disabled="collection.to === '/eng_ring' || collection.to === '/wed_ring'"
        :to="{ path: localePath(`/silver/${collection.to}`) }"
        tag="button"
        type="button"
        class="flex-grow-1 flex-shrink-0 btn shadow-none rounded-0 p-0 position-relative d-flex align-items-center justify-content-center w-100"
        style="padding: 10px"
        v-else
      >
        <figure
          class="collection-cart__figure w-100 position-relative overflow-hidden overlay"
        >
          <img
            v-lazy-load
            :data-src="`/images/collections/WEB/Silver/${collection.imgDesk}`"
            class="collection-cart__figure__image w-100"
            :alt="collection.title"
          >
        </figure>
        <h3 class="collection-cart__title text-center text-white position-absolute">{{ $t(collection.title) }}</h3>
      </nuxt-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardCollections',

  data () {
    return {
      list: [
        {
          title: 'menu.ring',
          imgDesk: 'Rings.png',
          id: 1,
          to: 'ring',
          img: 'ring.webp'
        },
        {
          title: 'menu.earring',
          imgDesk: 'Earrings.png',
          id: 2,
          to: 'earring',
          img: 'earring.webp'
        },
        {
          title: 'menu.necklace',
          imgDesk: 'Necklaces.png',
          id: 8,
          to: 'necklace',
          img: 'necklace.webp'
        },
        {
          title: 'menu.bracelet',
          imgDesk: 'Bracelets.png',
          id: 10,
          to: 'bracelet',
          img: 'bracelets.webp'
        },
        {
          title: 'menu.set',
          imgDesk: 'Sets.png',
          id: 3,
          to: 'set',
          img: 'set.webp'
        },
        {
          title: 'menu.pendant',
          imgDesk: 'Pendants.png',
          id: 6,
          to: 'pendant',
          img: 'pendant.webp'
        },
        {
          title: 'menu.accessories',
          imgDesk: 'Accessories.png',
          id: 5,
          to: 'accessories',
          img: 'accessories.webp'
        },
        {
          title: 'menu.bullions',
          imgDesk: 'Bullions.png',
          id: 16,
          to: 'gifts',
          img: 'bullions.webp'
        }
      ]
    }
  },
  computed: {
    switchRoute () {
      return this.$store.state.siteMode.sitemode === 'silver'
    }
  }
}
</script>

<style scoped>

.overlay:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  display: none;
}

.overlay:hover:before {
  display: block;
}

.greed-other {
  grid-template-columns: repeat(4, calc((100% - 10px) / 4));
}

.collection-cart__title {
  z-index: 3;
}

@media (max-width: 991.9px) {
  .greed-other {
    grid-template-columns: 100%;
  }
}
.collection-cart {
  flex: 0 0 calc(25% - 20px); /* Adjust the 10px value to your desired margin */
  margin: 10px;
}

.collection-cart-container {
  /* grid-template-columns: repeat(3, calc((100% - 40px) / 3));
  grid-gap: 50px 20px; */
  display: flex !important;
  flex-wrap: wrap;
  padding: 10px 0 50px 0;
  justify-content: center;
}
.collection-cart {
  flex: 0 0 calc(25% - 20px); /* Adjust the 10px value to your desired margin */
  margin: 10px;
}

.collection-cart,
.collection-cart:hover,
.collection-cart:after,
.collection-cart:visited {
  color: var(--primary-color-dark);
}

.collection-cart__figure__type {
  bottom: 0;
  left: 0;
  z-index: 5;
  padding: 24px;
  /* box-shadow: 0 4px 24px rgba(0, 0, 0, 0.06); */
  grid-template-columns: repeat(2, calc((100% - 10px) / 2));
  grid-gap: 10px;
  transform: translateY(103%);
  transition: all 300ms;
}
.collection-cart__figure:hover .collection-cart__figure__type {
  transform: translateY(0);
}
.collection-cart__figure__type .btn-outline {
  background: var(--white);
  padding: 12px;
}

.collection-cart__title {
  font-size: 25px;
  margin: 20px 0 0;
  line-height: 1;
  bottom: 7px
}

@media all and (max-width: 991.9px) {
  .collection-cart__figure__type {
    grid-template-columns: 100%;
    padding: 24px 12px;
  }
  .collection-cart__title {
    font-size: 16px;
  }
}

@media all and (max-width: 567px) {
  .collection-cart-container {
    grid-template-columns: repeat(3, calc((100% - 10px) / 3));
    grid-gap: 0;
    padding: 23px 0;
    max-width: 420px;
    margin: auto;
  }
  .collection-cart {
    flex: 0 0 33.3333%; /* Adjust the 10px value to your desired margin */
    margin: 0;
  }
  .collection-cart__title {
    font-size: 12px;
    font-weight: 400;
    margin: 10px 0 20px;
  }
}

@media all and (max-width: 360px) {
  .collection-cart-container {
    grid-template-columns: repeat(3, calc((100% - 10px) / 3));
    grid-gap: 0;
    padding: 23px 0;
    max-width: 300px;
    margin: auto;
  }
  .collection-cart-container {
    grid-template-columns: repeat(2, calc((100% - 10px) / 2));
  }
  .collection-cart {
    flex: 0 0 50%;
  }
}

</style>
